import { useEffect, useState } from "react";
import Layout from "../layout.tsx/app";
import { useActivationCode } from "../../stores/activation-code";
import { getData } from "../../api/get-data";
import {
  HiClipboardList,
  HiExclamationCircle,
  HiOutlineSearch,
  HiX,
} from "react-icons/hi";
import { Spinner } from "flowbite-react";
import Table from "../../components/tables/base";
import { parseDate } from "../../helper/date";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/tables/pagination";

const IndexActivationCode = () => {
  const [q, setQ] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const { activationCodes, setActivationCode } = useActivationCode();

  const navigate = useNavigate();

  const getActivationCode = async (
    search?: string,
    searchMode: boolean = false
  ) => {
    setLoading(true);
    try {
      const data = await getData("/activation-code", page, search, searchMode);
      return data;
    } catch {}
  };

  const handleSearch = async (input: string | undefined) => {
    setQ(input);
    const data = await getActivationCode(input ?? "", true);
    setActivationCode(data);
    setLoading(false);
  };

  const handleNext = () => {
    if (page === activationCodes?.last_page) {
      return;
    }

    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page === 1) {
      return;
    }

    setPage(page - 1);
  };

  useEffect(() => {
    Promise.all([getActivationCode()]).then((res) => {
      setActivationCode(res[0]);
      setLoading(false);
    });
  }, [page, loadingSubmit]);

  return (
    <Layout
      withPageTitle
      title="Kode Aktivasi Asesmen"
      pageTitleContent={
        <div className="flex items-center">
          <input
            type="text"
            className="rounded-l-lg border-gray-300"
            placeholder={"Cari disini..."}
            onChange={(e) => setQ(e.target.value)}
            disabled={loading}
            value={q}
          />
          {q && (
            <button
              onClick={() => handleSearch("")}
              className="py-3 px-2 border border-red-600 bg-red-600 text-white"
            >
              <HiX />
            </button>
          )}
          <button
            className={`${loading ? "py-2 px-3" : "p-3"} text-lg rounded-r-lg ${
              loading
                ? "bg-blue-500 text-white cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
            disabled={loading}
            onClick={() => handleSearch(q ?? "")}
          >
            {loading ? <Spinner size={"sm"} /> : <HiOutlineSearch />}
          </button>
        </div>
      }
    >
      <Table>
        <Table.Thead>
          <Table.Th>#</Table.Th>
          <Table.Th>Kode Aktivasi</Table.Th>
          <Table.Th>Modul</Table.Th>
          <Table.Th>Peserta</Table.Th>
          <Table.Th>Perusahaan</Table.Th>
          {/* <Table.Th>Psikolog</Table.Th> */}
          <Table.Th>Tgl. Aktif</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Opsi</Table.Th>
        </Table.Thead>
        <Table.Tbody>
          {loading ? (
            <Table.TrLoading cols={8} rows={5} />
          ) : (
            <>
              {activationCodes?.data.length === 0 ? (
                <Table.Tr>
                  <Table.Td cols={8} className="text-center py-3">
                    Tidak ada data ditemukan!
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {activationCodes?.data.map((item, key) => (
                    <Table.Tr key={key}>
                      <Table.Td>
                        {(
                          key +
                          1 +
                          activationCodes.per_page *
                            (activationCodes.current_page - 1)
                        ).toString()}
                      </Table.Td>
                      <Table.Td>
                        <a
                          href={`/activation-code/${item.code}`}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center gap-1"
                        >
                          <span>{item.code}</span>
                          <span
                            className={`text-xs uppercase text-white font-bold px-2 rounded ${
                              item.type === "logos"
                                ? "bg-blue-600"
                                : "bg-pink-600"
                            }`}
                          >
                            {item.type === "logos" ? "L" : "D"}
                          </span>
                        </a>
                      </Table.Td>
                      <Table.Td>{item.module_name ?? "-"}</Table.Td>
                      <Table.Td>{item.participant ?? "-"}</Table.Td>
                      <Table.Td>{item.company_name ?? "-"}</Table.Td>
                      {/* <Table.Td>{item.psikolog?.name ?? "-"}</Table.Td> */}
                      <Table.Td>
                        {item.start_at
                          ? item.end_at &&
                            `${parseDate(item.start_at)} - ${parseDate(
                              item.end_at
                            )}`
                          : "-"}
                      </Table.Td>
                      <Table.Td>
                        <span
                          className={`${
                            item.status === "belum digunakan" &&
                            "text-yellow-400"
                          } ${item.status === "proses" && "text-blue-600"} ${
                            item.status === "selesai" && "text-green-500"
                          } p-1 rounded text-xs inline-block uppercase`}
                        >
                          {item.status}
                        </span>
                      </Table.Td>
                      <Table.Td>
                        <>
                          {item.status === "belum digunakan" ? (
                            <div className="flex items-center gap-1">
                              <>-</>
                            </div>
                          ) : (
                            <div className="flex items-center gap-1">
                              <div
                                onClick={() =>
                                  navigate(`/activation-code/${item.code}`)
                                }
                                className="bg-blue-600 text-white p-1 cursor-pointer hover:bg-blue-700 rounded"
                              >
                                <HiExclamationCircle />
                              </div>
                              <div
                                className="bg-gray-600 text-white p-1 cursor-pointer hover:bg-gray-700 rounded"
                                onClick={() =>
                                  navigate(
                                    `/activation-code/${item.code}/sheet`
                                  )
                                }
                              >
                                <HiClipboardList />
                              </div>
                            </div>
                          )}
                        </>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </>
              )}
            </>
          )}
        </Table.Tbody>
      </Table>
      <Pagination
        currentPage={activationCodes?.current_page ?? 1}
        totalPage={activationCodes?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </Layout>
  );
};

export default IndexActivationCode;
